<template>
    <div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<!-- <el-form-item label="关键字:">
					<el-input
					placeholder="输入关键字筛选"
					v-model="filters.name">
					</el-input>
				</el-form-item> -->
				<el-form-item label="启用标识:">
					<el-select clearable v-model="filters.selectc">
						<el-option
							v-for="item in optionsData1"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<tool-bar class="editSom" :buttonList="buttonList" @callFunction="callFunction"></tool-bar>
				</el-form-item>
			</el-form>
			<!-- <el-tree
			class="filter-tree"
			:data="treeData"
			show-checkbox
			node-key="ID"
			ref="tree"
			v-loading="listLoading"
			check-strictly
			:highlight-current='true'
			:check-on-click-node="true"
			:default-checked-keys="[checkedkey]"
			:default-expanded-keys="checkedkey"
			:props="defaultProps"
			:default-expand-all="true"
			:filter-node-method="filterNode"
			@check-change="parentModules"
			style="max-width: 700px;max-height: 650px;overflow: auto;"
			>
			<span class="custom-tree-node" slot-scope="{ node,data }" style="font-size:16px;">
				<span>
					<i :class="data.Icon"></i>&nbsp;&nbsp;{{ node.label }}
				</span>              
			</span>
			</el-tree>  -->
			<el-table
				:data="treeData"
				v-if="isRouterAlive"
				style="margin-bottom: 20px;"
				row-key="id"
				border
				:cell-style="rowClass"
				highlight-current-row
				:props="defaultProps"
				v-loading="listLoading"
				@current-change="selectCurrentRow"
				default-expand-all
				lazy
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}"
			>
				<el-table-column prop="name" width="350" label="组织">
					<template slot-scope="scope">
						<i class="fa" :class="scope.row.icon"></i>
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column prop="schoolId" label="ID" width="200"></el-table-column>
				<el-table-column prop="province" label="省" width="200"></el-table-column>
				<el-table-column prop="city" label="市" width="200"></el-table-column>
<!--				<el-table-column prop="region" label="区/县" width="200"></el-table-column>-->
			</el-table>
		</el-col>
		<!--新增界面-->
		<el-dialog :title="'新增'" :visible.sync="dialogFormVisible" width="75%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
				<el-form-item v-if="ruleForm.belongName" label="所属组织：">
					{{ruleForm.belongName}}
				</el-form-item>
				<el-form-item label="组织名称：" prop="name">
					<el-input v-model="ruleForm.name" @keyup.enter.native="enterKey('ruleForm')" placeholder="请输入组织名称" style="width: 61%"></el-input>
				</el-form-item>
				<div style="display: flex;margin-bottom: 20px;margin-left:83px;">
					<div>
						<span class="selecRegionStyle">省:</span>
						<el-select @change="selectRowChange1" v-model="ruleForm.sheng" placeholder="请选择" clearable v-loading="listLoading2">
							<template v-for="item in shengArr">
								<el-option
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>
							</template>
						</el-select>
					</div>
					<div>
						<span class="selecRegionStyle">市:</span>
						<el-select @change="selectRowChange2" v-model="ruleForm.shi" placeholder="请选择" clearable v-loading="listLoading3">
							<template v-for="item in shiArr">
								<el-option                            
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>
							</template>
						</el-select>
					</div>
					<div>
						<span class="selecRegionStyle">区/县:</span>
						<el-select @change="selectRowChange3" v-model="ruleForm.qu" placeholder="请选择" clearable v-loading="listLoading4">
							<template v-for="item in xianArr">
								<el-option                            
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>
							</template>
						</el-select>
					</div>
				</div>
				<el-form-item label='官网名称'>
					<el-input style="width: 61%" v-model="ruleForm.websiteName"></el-input>
				</el-form-item>
				<el-form-item label='简称'>
					<el-input style="width: 61%" v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item label="是否启用：" prop="isEnable">
					<el-select v-model="ruleForm.isEnable" clearable placeholder="请选择是否启用">
						<el-option
							v-for="item in optionsData"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排列序号：">
					<el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" @keyup.enter.native="enterKey('ruleForm')" v-model="ruleForm.sortNo" placeholder="请输入排列序号" style="width: 61%"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitData('ruleForm')" :loading="addLoading">确 定</el-button>
			</div>
		</el-dialog>
		<!--编辑界面-->
		<el-dialog :title="'编辑'" :visible.sync="dialogFormVisiblec" width="75%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
				<el-form-item label="组织名称：" prop="name">
					<el-input v-model="ruleForm.name" disabled @keyup.enter.native="enterKey('ruleForm')" placeholder="请输入组织名称" style="width: 61%"></el-input>
				</el-form-item>
				<div style="display: flex;margin-bottom: 20px;margin-left:83px;">
					<div>
						<span class="selecRegionStyle">省:</span>
						<el-select @change="selectRowChange1" v-model="ruleForm.sheng" clearable placeholder="请选择" v-loading="listLoading2">
							<template v-for="item in shengArr">
                <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                </el-option>
							</template>
						</el-select>
					</div>
					<div>
						<span class="selecRegionStyle">市:</span>
						<el-select @change="selectRowChange2" v-model="ruleForm.shi" clearable placeholder="请选择" v-loading="listLoading3">
							<template v-for="item in shiArr">
                <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                </el-option>
							</template>
						</el-select>
					</div>
					<div>
						<span class="selecRegionStyle">区/县:</span>
						<el-select @change="selectRowChange3" v-model="ruleForm.qu" clearable placeholder="请选择" v-loading="listLoading4">
							<template v-for="item in xianArr">
                <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                </el-option>
							</template>
						</el-select>
					</div>
				</div>
				<el-form-item label='官网名称'>
					<el-input style="width: 61%" v-model="ruleForm.websiteName"></el-input>
				</el-form-item>
				<el-form-item label='简称'>
					<el-input style="width: 61%" v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item label="是否启用：" prop="isEnable">
					<el-select v-model="ruleForm.isEnable" clearable placeholder="请选择是否启用">
						<el-option
							v-for="item in optionsData"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排列序号：">
					<el-input type="text" disabled onkeyup="value=value.replace(/[^\d]/g,'')" @keyup.enter.native="enterKey('ruleForm')" v-model="ruleForm.sortNo" placeholder="请输入排列序号" style="width: 61%"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisiblec = false">取 消</el-button>
				<el-button type="primary" @click="submitData('ruleForm')" :loading="addLoading">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import toolBar from "@/components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getOrganizationTree,addOrganizationInfo,updateOrganizationInfo,deleteOrganizationInfo,getAreaDictionaryListWithCode } from "../../api/api";
import Qs from 'qs'
export default {
	components: { toolBar },
	data() {
		return {
			filterText: '',
			treeData: [],
			shengArr: [],
			shiArr: [],
			xianArr: [],
			listLoading: false,
			addLoading: false,
			listLoading2: false,
			listLoading3: false,
			listLoading4: false,
			isRouterAlive: true,
			checkedkey: [], 
			selectVal: '',
			filters: { //顶部筛选条件
				name: '',
				selectc: '',
			},
			optionsData1: [{
				value: '',
				label: '请选择'
			},{  //下拉选择
				value: true,
				label: '启用'
			},{
				value: false,
				label: '禁用'
			}],
			defaultProps: { 
				children: "children",
				hasChildren: "hasChildren",
				label: "name",
				id:'id' //可要可不要
			},
			optionsData: [{  //下拉选择
				value: true,
				label: '启用'
			},{
				value: false,
				label: '禁用'
			}],
			uniqueValue:'',//最后拿到的唯一选择的moduldCode值,相当于id
			buttonList: [],//增删改查按钮
			dialogTableVisible: false,
			dialogFormVisible: false,
			dialogFormVisiblec: false,
			formLabelWidth: '120px',
			ruleForm: {  //dialog数据
				dialogName: true,
				nodes_ID: '',
				name: '',
				isEnable: false,
				sheng: '',
				shi: '',
				qu: '',
				belongName: '',
				sortNo: 0,
				remark:undefined,
				websiteName:undefined,
			},
			rules: {
				name: [
					{ required: true, message: '请输入组织名称', trigger: 'blur' }
				],
				isEnable: [
					{ required: true, message: '请选择是否启用', trigger: 'change' }
				],
				sortNo: [
					{ required: true, message: '请输入排列序号', trigger: 'blur' }
				],
			}
		}
	},
	methods: {
		// 刷新
		doRefresh() {
			this.getOrganizations()
		},
		// 筛选
		filterNode(value, data) {
			if (!value) return true;
			return data.Name.indexOf(value) !== -1;
		},
		// 树形子节点选择-单选
		parentModules(data,checkbox,node){
			if(checkbox){
				// 后端返回的node-key不是id，是moduldCode
				this.$refs.tree.setCheckedKeys([data.ID]);
				this.uniqueValue =  this.$refs.tree.getCheckedKeys();
			}else{
				this.uniqueValue =  this.$refs.tree.getCheckedKeys();
				if(this.uniqueValue.length == 0){
					this.uniqueValue = ''
				}
			}
		},
		// 点击了查询，新增，编辑，删除
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		// 选中table某一行
		selectCurrentRow(val) {
			this.selectVal = val
		},
		// 获取树选中的节点
		//新增
		handleAdd(arrayId){
			// var nodes = this.$refs.tree.getCheckedNodes(arrayId);
			// if(nodes.length > 0){
			if(this.selectVal){
				this.shengArr = [],
				this.shiArr = [],
				this.xianArr = [],
				this.ruleForm.sheng = ''
				this.ruleForm.shi = ''
				this.ruleForm.qu = ''
				this.ruleForm.dialogName = true
				// this.ruleForm.nodes_ID = nodes[0].ID
				// this.ruleForm.belongName = nodes[0].Name
				this.ruleForm.nodes_ID = this.selectVal.id
				this.ruleForm.belongName = this.selectVal.name
				this.ruleForm.name = ''
				this.ruleForm.isEnable = true
				this.ruleForm.remark = undefined
				this.ruleForm.websiteName = undefined,
				this.ruleForm.sortNo = 0
				this.dialogFormVisible = true
				var params = {
					parentCode: "100000",
				}
				this.getCityPage(params);
			}else{
				this.$message({
					message: '请选择所属组织一项',
					type: 'warning'
				});
			}
		},
		enterKey(item) {
			this.submitData(item);
		},
		// 编辑
		handleEdit(arrayId){
			// var nodes = this.$refs.tree.getCheckedNodes(arrayId);
			// if(nodes.length > 0){
			if(this.selectVal){
				this.shengArr = [],
				this.shiArr = [],
				this.xianArr = [],
				this.ruleForm.sheng = ''
				this.ruleForm.shi = ''
				this.ruleForm.qu = ''
				this.ruleForm.dialogName = false
				// this.ruleForm.nodes_ID = nodes[0].ID
				// this.ruleForm.name = nodes[0].Name
				// this.ruleForm.region = nodes[0].IsEnable
				// this.ruleForm.sortNo = nodes[0].SortNo
				this.ruleForm.nodes_ID = this.selectVal.id
				this.ruleForm.name = this.selectVal.name
				this.ruleForm.isEnable = this.selectVal.isEnable
				this.ruleForm.sortNo = this.selectVal.sortNo
				this.ruleForm.websiteName = this.selectVal.websiteName
				this.ruleForm.remark = this.selectVal.remark
				this.ruleForm.belongName = ''
				this.dialogFormVisiblec = true
				var params = {
					parentCode: "100000",
				}
				this.getCityPage(params);
				if(this.selectVal.provinceCode){
				  this.ruleForm.sheng = this.selectVal.provinceCode
				  this.ruleForm.shi = this.selectVal.cityCode
				  this.ruleForm.qu = this.selectVal.districtCode
				  var params1 = {
				    parentCode: this.selectVal.provinceCode,
				  }
				  this.getCityPage2(params1)
				}
				if(this.selectVal.cityCode){
				  var params2 = {
				    parentCode: this.selectVal.cityCode,
				  }
				  this.getCityPage3(params2)
				}
				this.getCityPage(params);
				if(this.selectVal.provinceCode){
					this.ruleForm.sheng = this.selectVal.provinceCode
					this.ruleForm.shi = this.selectVal.cityCode
					this.ruleForm.qu = this.selectVal.districtCode
					var params1 = {
						parentCode: this.selectVal.provinceCode,
					}
					this.getCityPage2(params1)
				}
				if(this.selectVal.cityCode){
					var params2 = {
						parentCode: this.selectVal.cityCode,
					}
					this.getCityPage3(params2)
				}
			}else{
				this.$message({
					message: '请选择需要编辑一项',
					type: 'warning'
				});
			}
		},
		// 删除
		handleDel(arrayId){
			// var nodes = this.$refs.tree.getCheckedNodes(arrayId);
			// if(nodes.length > 0){
			if(this.selectVal){
				var params = {
				    id: this.selectVal.id,
				}
				this.$confirm('确认删除该记录吗?', '提示', {
					type: 'warning'
				}).then(() => {
					deleteOrganizationInfo(params).then(res => {
						if(res.data.success){
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
							this.doRefresh();
						}else{
							this.$message.error(res.data.message);
						}
					})
				})
				.catch(() => {
				})
			}else{
				this.$message({
					message: '请选择需要删除一项',
					type: 'warning'
				});
			}
		},
		// 默认勾选树节点
		setCheckedNode(){
			this.$refs.tree.setCheckedNodes([
				{ID:81}
			]);
		},
		// 获取树结构数据
		getOrganizations() {
			var _this = this
			var ID = JSON.parse(localStorage.getItem("user")).ID
			var params = {
				parentId: 0
			}
			if(this.filters.selectc === ''){}else{
				params.isEnable = this.filters.selectc
			}
			// if(this.filters.name){
			//   params.name = this.filters.name
			// }
			this.listLoading = true
			getOrganizationTree(params).then(res => {
				var data = res.data
				var resData = [data.response]
				resData.push()
				if(data.success){
					this.isRouterAlive = false;
					this.$nextTick(function () {
						this.isRouterAlive = true;
					})
					this.selectVal = ''
					this.listLoading = false
					if(data.response.name == "根节点"){
						var newObjData = JSON.stringify(data.response.children).replace(/\hasChildren/g,"HasChildren")
						_this.treeData = JSON.parse(newObjData)
					}else{
						_this.treeData = resData
					}
					// _this.treeData = resData
				}
			})
		},
		// 新增/编辑
		submitData(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					var ID = JSON.parse(localStorage.getItem("user")).ID
					// var params = Object.assign({}, this.formName);
					var params = {
						name: this.ruleForm.name,
						isEnable: this.ruleForm.isEnable,
						websiteName:this.ruleForm.websiteName,
						remark:this.ruleForm.remark,
						sortNo: this.ruleForm.sortNo || 0,
						icon: 'el-icon-folder-opened'
					}
					if(this.ruleForm.sheng){
						params.provinceCode = this.ruleForm.sheng
					}else{
                        params.provinceCode = "";
                    }
					if(this.ruleForm.shi){
						params.cityCode = this.ruleForm.shi
					}else{
                        params.cityCode = "";
                    }
					if(this.ruleForm.qu){
						params.districtCode = this.ruleForm.qu
					}else{
                        params.districtCode = "";
                    }
					if(this.ruleForm.dialogName){
						params.parentId = this.ruleForm.nodes_ID,
						this.addLoading = true
						addOrganizationInfo(params).then(res => {
							if(res.data.success){
								this.$message({
									message: '新增成功！',
									type: 'success'
								});
								this.doRefresh();
								this.dialogFormVisible = false;
								this.addLoading = false;
							}else{
								this.$message.error(res.data.message);
								this.addLoading = false;
							}
						})
					}else{
						params.id = this.ruleForm.nodes_ID,
						this.addLoading = true
						updateOrganizationInfo(params).then(res => {
							if(res.data.success){
								this.$message({
									message: '编辑成功！',
									type: 'success'
								});
								this.doRefresh();
								this.dialogFormVisiblec = false;
								this.addLoading = false;
							}else{
								this.$message.error(res.data.message);
								this.addLoading = false;
							}
						})
					}
				} else {
					return false;
				}
			});
		},
		// 获取行政区域
		getCityPage(params) {
			this.listLoading2 = true
			getAreaDictionaryListWithCode(params).then((res) => {
				var result = res.data
				if(result.success){
					this.shengArr = result.response.reverse()
					this.listLoading2 = false
				}
			})
		},
		getCityPage2(params) {
			this.listLoading3 = true
			getAreaDictionaryListWithCode(params).then((res) => {
				var result = res.data
				if(result.success){
					this.shiArr = result.response.reverse()
					this.listLoading3 = false
				}
			})
		},
		getCityPage3(params) {
			this.listLoading4 = true
			getAreaDictionaryListWithCode(params).then((res) => {
				var result = res.data
				if(result.success){
					this.xianArr = result.response.reverse()
					this.listLoading4 = false
				}
			})
		},
		selectRowChange1(row) {
			var params = {
				parentCode: row,
			}
			if(row){
				this.getCityPage2(params);
			}
			this.ruleForm.shi = ''
			this.ruleForm.qu = ''
		},
		selectRowChange2(row) {
			this.$forceUpdate()
			var params = {
				parentCode: row,
			}
			if(row){
				this.getCityPage3(params);
			}
			this.ruleForm.qu = ''
		},
		selectRowChange3(row) {
			this.$forceUpdate()
		},
		rowClass({columnIndex}){
			return 'padding:8px!important;'
		},
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		}
	},
	created() {
		this.getOrganizations()
	},
	mounted() {
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
};
</script>
<style lang="stylus" scoped>
.treeChe,.searchSom {
	width 800px;
}
.searchSom {
	display flex;
}
.editSom {
	margin-left 10px;
}
.selecRegionStyle {
	font-size 14px;
	color #606266
	margin 0 15px;
}
</style>
